<template>
    <div class="empty">
        <img :src="currentData.current.src" >
        <p class="text-f14 text-fc3">{{ currentData.current.msg }}</p>
    </div>
</template>

<script setup lang="ts">
import img1 from "@/assets/images/default_404.svg";
import img2 from "@/assets/images/default_function.svg";
import img3 from "@/assets/images/default_my_empty.svg";
import img4 from "@/assets/images/default_network.svg";

const props = defineProps(['index'])

const data = reactive({
    current:null,
    list:[
        {
            src:img1,
            msg:"404服务器报错～",
        },
        {
            src:img2,
            msg:"功能在路上～"
        },
        {
            src:img3,
            msg:"暂无内容～"
        },
        {
            src:img4,
            msg:"网络加载失败～"
        },
    ]
})

const currentData = reactive({
    current:data.list[0]
})

onMounted(()=>{
    if(props.index) currentData.current = data.list[props.index]
})

</script>

<style lang="less" scoped>
.empty{
    @apply flex flex-col justify-center items-center;
    width: 100%;
    height: 100%;
    p{
        margin-top: 20px;
    }
}
</style>